import {all, call, put, select, takeLatest} from 'redux-saga/effects';
import {fetchEnd, fetchStart, refreshView, showNotification} from 'react-admin';
import OdataServiceProvider from "../Helpers/odataServiceProvider";
import {acknowledgeChanges, getTimeSheetSummery, payrollMembers} from "../Helpers/matesApi";
import _ from "lodash";
import moment from "moment";
import {getNewHires, getTeams, getTerminations, updateNewHires, updateTermination} from "../Helpers/timeAttendanceApi";

export default function* matesSaga() {
    // yield takeLatest('UPDATE_TEAM', function* (action) {
    //     const {payload} = action
    //     yield put(fetchStart());
    //     const allTeams = call(OdataServiceProvider.getList,'sites',{pagination:{},sort:{},filter:{}})
    //     const allEmps = call(OdataServiceProvider.getList,'employees',{pagination:{},sort:{},filter:{filter:'active eq true'}})
    //
    //     const teamMembers = yield call(getMonthlyTeamSummery,payload.date)
    //
    //     // const employees = yield call(getMonthlyTeamSummery,payload.date)
    //
    //
    //     const updates = teamMembers.map(t => {
    //         const emp = allEmps.find(e=>e.mateId === t.mateId)
    //         const team = allTeams.find(at=>at.name === t.team)
    //         if(team&& emp) {
    //             return call(OdataServiceProvider.update, 'employees', {data: {siteId:team.id}, id: emp.id})
    //         }
    //     })
    //
    //     const res = yield  all (updates);
    //
    //     yield  put({type:'DONE_UPDATING_TEAMS'})
    //     yield put(fetchEnd());
    //
    // })
    yield takeLatest('LOAD_MATES_CHANGES', function* (action) {
        const {payload} = action

        try {
            // yield put(fetchStart());
            yield  put({type:'SET_MATES_LOADING', payload: true})
            // const {data:timesheet} = yield call(getTimeSheetSummery,{atDate:payload.atDate,month:payload.month})
            const {data:payrollChanges} = yield call(payrollMembers,{startDate:payload.startDate,endDate:payload.endDate})

            const {data: allEmps } = yield  call(OdataServiceProvider.getList,'employees',{pagination:{},sort:{},filter:{filter:'active eq true'}})

          //  console.log(mateMembers)

           const mateIds = allEmps.map(e=>e.mateId && e.mateId.toLowerCase());
            // console.log(mateMembers)
           const newEmps = payrollChanges.newMembers.filter(m=>!mateIds.includes(m.mateId)).map(m=>({...m,payrollStatus:'Applied'}));
            const inactiveEmps = payrollChanges.inactiveMembers.map(m=>({...m.member, ...m, memberId:m.member.id,payrollStatus:'Applied'})).filter(m=>mateIds.includes(m.mateId && m.mateId.toLowerCase()));

            const updatedEmps = payrollChanges.updatedMembers.map(m=>({...m.member, ...m, payrollStatus:'Applied'})).filter(m=>mateIds.includes(m.mateId && m.mateId.toLowerCase()));


            //console.log(inactiveEmps)
            // const idleEmps = timesheet.filter(t=>t.status==='Idle')
            yield  put({type:'SET_MATES_EMPS', payload: {newEmps, inactiveEmps, updatedEmps}})


        }
        catch (e) {
            // yield put(fetchEnd());
            yield  put({type:'SET_MATES_ERROR', payload: true})
            yield put(showNotification(`Error Fetching Changes`, "error"))
                console.log(e)
        }
            // yield put(fetchEnd());


        // const newEmps = mateMembers.filter(m=>!mateIds.includes(m.mateId)).map(m=>({
        //     name:m.member.name,
        //     fatherName:m.member.fatherName,
        //     gFatherName:m.member.gFatherName,
        //     // tinNo: '',
        //     // pensionNo: '',
        //     // bankBranch: '',
        //     employmentType: m.member.employmentType,
        //   //  companyBankId: 0,
        //    // siteId: 0,
        //     startDate: new Date(),
        //     contractEndDate: new Date(),
        //     // address: {
        //     //     phoneNumbers:[{number:'', type:''}],
        //     //     email: '',
        //     //     addressLine: '',
        //     //     city: '',
        //     //     subcity: '',
        //     //     kebele: '',
        //     //     woreda: '',
        //     //     houseNumber: ''
        //     //
        //     // },
        //     basicSalary:'',
        //     earnings: [{
        //         name:'',
        //         value:0
        //     }],
        //     // positionId: 0,
        //     bankAccountNumber:'',
        //     mateId:m.mateId,
        //     active:true
        //
        //
        // }))

    })

    yield takeLatest('LOAD_MATES_TIMESHEET', function* (action) {
        const {payload} = action

        try {
            // yield put(fetchStart());
            yield  put({type:'SET_MATES_LOADING', payload: true})
            const {data:timesheet} = yield call(getTimeSheetSummery,{atDate:payload.atDate,month:payload.month,periodStart:payload.periodStart,periodEnd:payload.periodEnd})
            const {data: allSites } = yield  call(OdataServiceProvider.getList,'sites',{pagination:{},sort:{},filter:{}})
            const payrolls = yield select(state=>state.runPayroll.payrolls)


            yield  put({type:'SET_MATES_TIMESHEET', payload: {timesheet, allSites, payrolls}})


        }
        catch (e) {
            // yield put(fetchEnd());
            yield  put({type:'SET_MATES_ERROR', payload: true})
            yield put(showNotification(`Error Fetching Timesheet`, "error"))
            console.log(e)
        }
        // yield put(fetchEnd());


        // const newEmps = mateMembers.filter(m=>!mateIds.includes(m.mateId)).map(m=>({
        //     name:m.member.name,
        //     fatherName:m.member.fatherName,
        //     gFatherName:m.member.gFatherName,
        //     // tinNo: '',
        //     // pensionNo: '',
        //     // bankBranch: '',
        //     employmentType: m.member.employmentType,
        //   //  companyBankId: 0,
        //    // siteId: 0,
        //     startDate: new Date(),
        //     contractEndDate: new Date(),
        //     // address: {
        //     //     phoneNumbers:[{number:'', type:''}],
        //     //     email: '',
        //     //     addressLine: '',
        //     //     city: '',
        //     //     subcity: '',
        //     //     kebele: '',
        //     //     woreda: '',
        //     //     houseNumber: ''
        //     //
        //     // },
        //     basicSalary:'',
        //     earnings: [{
        //         name:'',
        //         value:0
        //     }],
        //     // positionId: 0,
        //     bankAccountNumber:'',
        //     mateId:m.mateId,
        //     active:true
        //
        //
        // }))

    })

    yield takeLatest('SAVE_MATES_CHANGES', function* (action) {
        const {payload} = action
       try {

           yield  put({type:'SET_MATES_SAVING', payload: true})
           const {data: allSites } = yield  call(OdataServiceProvider.getList,'sites',{pagination:{},sort:{},filter:{}})





           const {newEmps,inactiveEmps,updatedEmps, timesheet, updateSites} = payload;
           //console.log(timesheet)
           const appliedHires = newEmps?newEmps.filter(m=>m.payrollStatus==="Applied"):[]
           if(appliedHires.length)
           {
               // console.log({appliedHires})
               const {data }= yield call(OdataServiceProvider.getOne,"companies",{ id: localStorage.getItem('companyId'), expand:`earnings($filter = Scope eq 'Company'),deductions($filter = Scope eq 'Company'),expenses($filter = Scope eq 'Company'),CompanySettings`})
               //console.log(data)
               const settingDays= data.companySettings.find(s=>s.name==='Pension_StartDays')
               const settingFullMonth= data.companySettings.find(s=>s.name==='Pension_FullMonth')
               const StartAfterDays = settingDays?+settingDays.value:0
               const StartFullMonth = settingFullMonth?settingFullMonth.value==='true':true


               const er = data.earnings.map(e => {
                   const f = _.omit(e, ["id", "createdDate", "modifiedDate", "version", "@odata.context", "@odata.etag","positionId", "employeeId"])
                   f.parentScope = f.scope
                   f.scope="Employee"
                   f.copyRefId= e.id
                   return f
               }  )

               const d = data.deductions.map(e => {
                   const f = _.omit(e, ["id", "createdDate", "modifiedDate", "version", "@odata.context", "@odata.etag","positionId", "employeeId"])
                   f.parentScope = f.scope
                   f.scope="Employee"
                   f.copyRefId= e.id
                   return f
               }  )


               const ex = data.expenses.map(e => {
                   const f = _.omit(e, ["id", "createdDate", "modifiedDate", "version", "@odata.context", "@odata.etag","positionId", "employeeId"])
                   f.parentScope = f.scope
                   f.scope="Employee"
                   f.copyRefId= e.id
                   return f
               }  )

               const {data: templateEarnings }= yield call(OdataServiceProvider.getList,"earnings",{filter:{filter:`scope eq 'Template'`} })
               const {data: banks }= yield call(OdataServiceProvider.getList,"companyBanks")
               const {data: positions }= yield call(OdataServiceProvider.getList,"positions",{filter:{expand:`earnings,deductions,expenses`}})
               // const {data: sites }= yield call(OdataServiceProvider.getList,"sites")
              // console.log(appliedHires)
               const emps = appliedHires.map(m=>{

                          m.earnings = er.filter(i=>i.appliesTo.includes(m.employmentType)&&(!m.citizenship||!i.appliesToCitizens||!i.appliesToCitizens.length||i.appliesToCitizens.map(ap=>ap.toLowerCase()).includes(m.citizenship.toLowerCase().replace(/\s+/g, ''))))
                           m.deductions = d.filter(i=>i.appliesTo.includes(m.employmentType)&&(!m.citizenship||!i.appliesToCitizens||!i.appliesToCitizens.length||i.appliesToCitizens.map(ap=>ap.toLowerCase()).includes(m.citizenship.toLowerCase().replace(/\s+/g, '')))) // d.filter(i=>i.deductionType.toLowerCase()!=="emppension" || ((!e.citizenship || e.citizenship.toLowerCase().replace(/\s+/g, '') === 'ethiopian' || e.citizenship.toLowerCase().replace(/\s+/g, '') === '')&&e.employmentType!=="PartTime" ));           // skip non ethiopians and parttimeers on penstions
                           m.expenses = ex.filter(i=>i.appliesTo.includes(m.employmentType)&&(!m.citizenship||!i.appliesToCitizens||!i.appliesToCitizens.length||i.appliesToCitizens.map(ap=>ap.toLowerCase()).includes(m.citizenship.toLowerCase().replace(/\s+/g, ''))))

                   const pension = m.deductions.find(d=>d.deductionType==="EmpPension")
                   const compPension = m.expenses.find(d=>d.type==="CompanyPension")



                   if(pension&&m.startDate)
                   {

                       // console.log(companyData)
                       pension.startDate = moment(m.startDate).add(StartAfterDays,"day")
                       if(StartFullMonth){
                           pension.startDate = pension.startDate.startOf('month')
                       }
                      // form.change('deductions', deductions)

                   }

                   if(compPension&&m.startDate)
                   {

                       // console.log(companyData)
                       compPension.startDate = moment(m.startDate).add(StartAfterDays,"day")
                       if(StartFullMonth){
                           compPension.startDate = pension.startDate.startOf('month')
                       }
                     //  form.change('expenses', expenses)

                   }





                       m.employmentType = m.employmentType||"FullTime"
                           m.citizenship=  m.citizenship || "Ethiopian"
                   m.address = m.address || {}
                   m.address.phones = m.address.phones || []
                   m.bankAccounts = m.bankAccounts || []

                   if(m.earning && m.earning.additionalEarnings) {
                       m.earning.additionalEarnings.map(er => {
                           const template = templateEarnings.find(e => e.name.toLowerCase().replace(/\s+/g, '') === er.name.toLowerCase().replace(/\s+/g, ''))
                       //   console.log("template",template, er.name, templateEarnings)
                           if (template) {
                               const x = _.pick(template, ['name', 'taxable', 'taxOption', 'limitByValue', 'valueType', 'dayBased'])
                               //console.log(e.earnings)
                               m.earnings.push({
                                   ...x,
                                   value: er.value,
                                   templateId: template.id,
                                   earningType: template.name
                               })
                               // if(!e.earnings){
                               //     e.earnings = []
                               // }
                               //   e.earnings.push(
                               //       {...x,value:e[key]}
                               //   )

                           }
                       })
                   }

                       if(m.bankAccounts &&  m.bankAccounts[0])
                       {
                           const bank = banks.find(p=>p.bank.name.toLowerCase().replace(/\s+/g, '')===m.bankAccounts[0].type.toLowerCase().replace(/\s+/g, ''))
                           if(bank)
                           {m.companyBankId = bank.id;}
                       }

                       if(m.jobTitle)
                       {
                           const pos = positions.find(p=>p.name.toLowerCase().replace(/\s+/g, '')===m.jobTitle.toLowerCase().replace(/\s+/g, ''))

                           if(pos)
                           {
                               m.positionId = pos.id;

                               const er = pos.earnings.filter(i=>i.appliesTo.includes(m.employmentType)&&(!m.citizenship||!i.appliesToCitizens||!i.appliesToCitizens.length||i.appliesToCitizens.map(ap=>ap.toLowerCase()).includes(m.citizenship.toLowerCase().replace(/\s+/g, '')))).map(i=> {
                                   // consoli.log(e)
                                   const f = _.omit(i, ["id", "createdDate", "modifiedDate", "version", "@odata.context", "@odata.etag","positionId", "employeeId"])
                                   f.parentScope = f.scope
                                   f.scope="Employee"
                                   f.copyRefId= i.id
                                   //  f.earningType = i.earningType
                                   return f
                               }  )

                               const d = pos.deductions.filter(i=>i.appliesTo.includes(m.employmentType)&&(!m.citizenship||!i.appliesToCitizens||!i.appliesToCitizens.length||i.appliesToCitizens.map(ap=>ap.toLowerCase()).includes(m.citizenship.toLowerCase().replace(/\s+/g, '')))).map(i=> {
                                   const f = _.omit(i, ["id", "createdDate", "modifiedDate", "version", "@odata.context", "@odata.etag","positionId", "employeeId"])
                                   f.parentScope = f.scope
                                   f.scope="Employee"
                                   f.copyRefId= i.id
                                   return f
                               }  )

                               const ex = pos.expenses.filter(i=>i.appliesTo.includes(m.employmentType)&&(!m.citizenship||!i.appliesToCitizens||!i.appliesToCitizens.length||i.appliesToCitizens.map(ap=>ap.toLowerCase()).includes(m.citizenship.toLowerCase().replace(/\s+/g, '')))).map(i=> {
                                   const f = _.omit(i, ["id", "createdDate", "modifiedDate", "version", "@odata.context", "@odata.etag","positionId", "employeeId"])
                                   f.parentScope = f.scope
                                   f.scope="Employee"
                                   f.copyRefId= i.id
                                   return f
                               }  )



                               m.earnings =  m.earnings.concat(er) //pos.earnings.filter(i=>i.appliesTo.includes(e.employmentType)&&(!e.citizenship||!i.appliesToCitizens||!i.appliesToCitizens.length||i.appliesToCitizens.map(ap=>ap.toLowerCase()).includes(e.citizenship.toLowerCase().replace(/\s+/g, ''))))) ;
                               m.deductions = m.deductions.concat(d) // pos.deductions.filter(i=>i.appliesTo.includes(e.employmentType)&&(!e.citizenship||!i.appliesToCitizens||!i.appliesToCitizens.length||i.appliesToCitizens.map(ap=>ap.toLowerCase()).includes(e.citizenship.toLowerCase().replace(/\s+/g, ''))))) // d.filter(i=>i.deductionType.toLowerCase()!=="emppension" || ((!e.citizenship || e.citizenship.toLowerCase().replace(/\s+/g, '') === 'ethiopian' || e.citizenship.toLowerCase().replace(/\s+/g, '') === '')&&e.employmentType!=="PartTime" ));           // skip non ethiopians and parttimeers on penstions
                               m.expenses = m.expenses.concat(ex)//pos.expenses.filter(i=>i.appliesTo.includes(e.employmentType)&&(!e.citizenship||!i.appliesToCitizens||!i.appliesToCitizens.length||i.appliesToCitizens.map(ap=>ap.toLowerCase()).includes(e.citizenship.toLowerCase().replace(/\s+/g, '')))));
                               // console.log(er,e)
                           }



                       }
                       if(m.team)
                       {
                           const site = allSites.find(p=>p.name.toLowerCase().replace(/\s+/g, '')===m.team.toLowerCase().replace(/\s+/g, ''))
                           if(site)
                           {m.siteId = site.id;}
                       }



                   return {
                       name:m.name,
                       fatherName:m.fatherName,
                       gFatherName:m.gFatherName,
                       tinNo: m.tinNo,
                       pensionNo: m.pensionNo,
                       bankBranch: '', //todo
                       employmentType: m.employmentType,
                       citizenship: m.citizenship,
                       earnings : m.earnings,
                       deductions: m.deductions,
                       expenses: m.expenses,
                       companyBankId: m.companyBankId,
                       positionId: m.positionId,
                       siteId: m.siteId,
                       startDate: m.startDate,
                       contractEndDate: m.endDate,
                       address: {
                           phoneNumbers:m.address.phones.map(p=>({type:p.type, number:p.value})),
                           phoneNumber:(m.address.phones[0]||{}).value,
                           email: m.address.email,
                           addressLine: m.address.locationText,
                           city: m.address.city,
                           subcity: m.address.subcity,
                           kebele: m.address.kebele,
                           woreda: m.address.woreda,
                           houseNumber: m.address.houseNumber

                   },
                       basicSalary:m.earning.rate,
                       bankAccountNumber:m.bankAccounts[0]&&m.bankAccounts[0].value,
                       mateId:m.mateId.toLowerCase().replace(/\s+/g, ''),

                   }
               })

               //console.log(emps)
               const res = yield call(OdataServiceProvider.createMany,'employees',{data:emps})

               if(emps.length) {
                   yield put(showNotification(`${emps.length} New Employees Imported`, "info"))
               }

               yield  put({type:'SET_MATES_EMPS_SAVED', payload: {appliedIds:appliedHires.map(p=>p.id)}})

           }

           const {data:allEmps} = yield call(OdataServiceProvider.getList,'employees',{pagination:{},sort:{},filter:{filter:'active eq true'}})

           const appliedTerminations = inactiveEmps?inactiveEmps.filter(m=>m.payrollStatus==="Applied"):[]
           if(appliedTerminations.length) {
               //console.log({appliedTerminations})
               const mateIds= appliedTerminations.map(e=>e.mateId.toLowerCase());
               const emps = allEmps.filter(e=>mateIds.includes(e.mateId.toLowerCase()))
               const res =  yield call(OdataServiceProvider.updateMany,'employees',{ids:emps.map(e=>e.id),data:{active:false}})



               if(emps.length) {
                   yield put(showNotification(`${emps.length} Employees Deactivated`, "info"))
               }

           }


           const appliedUpdates= updatedEmps?updatedEmps.filter(m=>m.payrollStatus==="Applied"):[]
           if(appliedUpdates.length) {
               // console.log({appliedTerminations})
               //const mateIds= appliedUpdates.map(e=>e.mateId.toLowerCase());
               //const emps = allEmps.filter(e=>mateIds.includes(e.mateId.toLowerCase()))
               const calls = []
               appliedUpdates.forEach(m=> {
                   const emp = allEmps.find(e=>e.mateId.toLowerCase()===m.mateId.toLowerCase())
                   calls.push(call(OdataServiceProvider.update, 'employees', {
                       id: emp.id,
                       data: {
                           ...(m.updateTypes.includes('salary') && {basicSalary: m.salary}),
                           ...(m.updateTypes.includes('contractDate') && { contractEndDate: m.contractEndDate}),

                           //todo  earnings
                       }
                   }))
                   }
               )
               const res = yield all(calls)

               if(calls.length) {
                   yield put(showNotification(`${calls.length} Employees Updated`, "info"))
               }

           }

           const ah =  appliedHires.map(p=>_.pick(p,['_id','payrollStatus','memberId']))
           const at = appliedTerminations.map(p=>_.pick(p,['_id','payrollStatus','memberId']))
           const updates = appliedUpdates.map(p=>_.pick(p,['_id','payrollStatus','memberId']))
            //console.log({appliedUpdates, updatedEmps})
           const ack = yield call(acknowledgeChanges, ah,at, updates )


           if(updateSites)
          //change sites
           {
               //console.log("changing sites", {timesheet})
               if (timesheet && timesheet.length) {
                   const calls = []
                   let siteUpdated = 0;
                   timesheet.map(t => {
                       //console.log(t)
                       if (t.team) {
                           const site = allSites.find(s => s.name.toLowerCase().replace(/\s+/g, '') === t.team.toLowerCase().replace(/\s+/g, ''))
                           const emp = allEmps.find(e => e.mateId && t.mateId && e.mateId.toLowerCase().replace(/\s+/g, '') === t.mateId.toLowerCase().replace(/\s+/g, ''))
                           if (emp && site && emp.siteId !== site.id) {
                               calls.push(call(OdataServiceProvider.update, 'employees', {
                                   id: emp.id,
                                   data: {siteId: site.id}
                               }))
                               siteUpdated++
                           }
                       }


                   })
                   yield all(calls)
                   if (siteUpdated) {
                       yield put(showNotification(`${siteUpdated} Sites Updated`, "info"))
                   }


               }
           }

           if (appliedHires.length || appliedUpdates.length || appliedTerminations.length || updateSites) {  // refresh the payroll if there is a site change or new hire
               yield put({type: 'LOAD_PAYROLL_EMPLOYEES', payload: {refresh: true, skipLoading: true}})
           }

          //todo haha a cheap tick, No!
          yield call(()=>new Promise(resolve => {
              setTimeout(resolve, 500)
          }))

          const payrollData =  yield select(state=>state.runPayroll.payrolls);
         // console.log(payrollData)


           if(payrollData&&timesheet&&timesheet.length) {
               const {data:OvertimeTypes} = yield call(OdataServiceProvider.getList,"overtimeTypes")

               const ts = timesheet.map(d=>{
                       const overtimes = []
                       //
                       Object.keys(d.overtimes).map(key=>{
                           const name = key==='restDay'?'sunday':key;
                           const type =  OvertimeTypes.find(o=>o.name.replace(/\s+/g, '').toLowerCase() === name.replace(/\s+/g, '').toLowerCase())
                           if(type && d.overtimes[key]>0 ) {
                               overtimes.push({typeId: type.id, rate: type.rate, hrs: +d.overtimes[key].toFixed(2)})
                           }
                       })

                   // if(+d.transportPayableDays>=1)
                   // {
                   //       d.transportPayableDays+=1
                   //     d.transportPayableDays = Math.max(30,d.transportPayableDays)
                   // }

                       return { ...d,
                           normalPayDays: d.member.employmentType==="Casual"?d.payableDays:-1,
                           absentDays: d.member.employmentType==="Casual"?0:d.absentDays,
                           overtimes,
                           ot:overtimes,


                           // monthlyHours:d.fullExpectedHrs,
                           // actualHoursWorked:d.workHrs,hoursWorked:+(d.adjustedFullPayHours||d.fullPayHours||d.payHrs||0).toFixed(1)

                       }
                   }


               )
               // notify('Sorry Something went wrong in fetching your company time attendance data','error')
              yield put({type:"PAYROLL_TIMESHEET_UPDATED",payload: {timeSheets:ts, idField:'mateId', source:'tik'}})



               // const {data:overtimeTypes} = yield call(OdataServiceProvider.getList,"overtimeTypes")
               //  //  console.log(overtimeTypes,"ot")
               //
               // const timesheetLookup = {}
               // const lookup = {}
               //
               // for (let i = 0; i < timesheet.length; i++) {
               //     if(timesheet[i].mateId) {
               //         lookup[timesheet[i].mateId.toLowerCase()] = timesheet[i]
               //     }
               //     else {
               //         // console.log(timesheet[i])
               //     }
               // }
               // const calls =[]
               // payrollData.map(p=>{
               //
               //     if(p&&p.mateId&&p.status!=='Paid') {
               //         //console.log(p.totalDaysInPayPeriod)
               //        // console.log(p)
               //         const timeSheet = lookup[p.mateId.toLowerCase()] //timesheet.find(t => p.mateId===t.mateId);
               //         if (timeSheet) {
               //
               //             if(timeSheet.hoursWorked)
               //             {
               //                 calls.push(put({
               //                     type: 'PAYROLL_ITEM_CHANGED', payload: {
               //                         event: {
               //                             field: 'hoursWorked',
               //                             value: +(timeSheet.hoursWorked).toFixed(0),
               //                             dataItem: p
               //                         }
               //                     }
               //                 }))
               //                 calls.push(put({
               //                     type: 'PAYROLL_ITEM_CHANGED', payload: {
               //                         event: {
               //                             field: 'fromTimeAttendance',
               //                             value: +(timeSheet.hoursWorked).toFixed(0),
               //                             dataItem: p
               //                         }
               //                     }
               //                 }))
               //
               //             }
               //
               //
               //
               //
               //          //
               //          //    // timeSheet.hoursWorked && dispatch({
               //          //    //     type: 'PAYROLL_ITEM_CHANGED', payload: {
               //          //    //         event: {
               //          //    //             field: 'hoursWorked',
               //          //    //             value: +(timeSheet.hoursWorked).toFixed(2),
               //          //    //             dataItem: p
               //          //    //         }
               //          //    //     }
               //          //    // })
               //          //    // if(source === "tik") {
               //          //    //     timeSheet.hoursWorked && dispatch({
               //          //    //         type: 'PAYROLL_ITEM_CHANGED', payload: {
               //          //    //             event: {
               //          //    //                 field: 'fromTimeAttendance',
               //          //    //                 value: +(timeSheet.hoursWorked).toFixed(2),
               //          //    //                 dataItem: p
               //          //    //             }
               //          //    //         }
               //          //    //     })
               //          //    // }
               //          //    timeSheet.overtimes && calls.push(put({
               //          //        type: 'PAYROLL_ITEM_CHANGED', payload: {
               //          //            event: {
               //          //                field: 'overtimes',
               //          //                value: Object.keys(timeSheet.overtimes).map(key=>{
               //          //                    const type = overtimeTypes.find(o=>o.name.replace(/\s+/g, '').toLowerCase() === key.replace(/\s+/g, '').toLowerCase())
               //          //                    if(type)
               //          //                    {
               //          //                        return {typeId: type.id, rate: type.rate, hrs: timeSheet.overtimes[key]}
               //          //                    }
               //          //
               //          //                    }
               //          //                ).filter(o=>o.typeId),
               //          //                dataItem: p
               //          //            }
               //          //        }
               //          //    }))
               //          //    timeSheet.leaveDays && calls.push(put({
               //          //        type: 'PAYROLL_ITEM_CHANGED', payload: {
               //          //            event: {
               //          //                field: 'leaveDays',
               //          //                value: timeSheet.leaveDays,
               //          //                dataItem: p
               //          //            }
               //          //        }
               //          //    }))
               //          //
               //          //    timeSheet.payableDays && timeSheet.member &&timeSheet.member.employmentType==='Casual' && calls.push(put({
               //          //        type: 'PAYROLL_ITEM_CHANGED', payload: {
               //          //            event: {
               //          //                field: 'normalPayDays',
               //          //                value: timeSheet.payableDays,
               //          //                dataItem: p
               //          //            }
               //          //        }
               //          //    }))
               //          //
               //          // //   console.log(timeSheet)
               //          //
               //          //    if(timeSheet.transportPayableDays||timeSheet.transportPayableDays===0) {
               //          //        //console.log(p)
               //          //        const newEarnings = p.payrollFixedEarnings.slice(0); // Create a shallow copy of the roles
               //          //        //const name = newRoles[index].name
               //          //
               //          //        const index = newEarnings.findIndex(e=>e.name.toLowerCase().replace(/\s+/g, '')==="transportallowance")
               //          //        //console.log(index)
               //          //        if(index>=0) {
               //          //            const val = (newEarnings[index].originalValue / p.totalDaysInPayPeriod||30) * timeSheet.transportPayableDays;
               //          //
               //          //            newEarnings[index] = {
               //          //                ...newEarnings[index],
               //          //                entitledDays: +timeSheet.transportPayableDays,
               //          //                fullValue: val,
               //          //                value: val
               //          //            }; // Set the new value
               //          //            //console.log(newEarnings)
               //          //            calls.push(put({
               //          //                type: 'PAYROLL_ITEM_CHANGED', payload: {
               //          //                    event: {
               //          //                        field: 'payrollFixedEarnings',
               //          //                        value: newEarnings,
               //          //                        dataItem: p
               //          //                    }
               //          //                }
               //          //            }))
               //          //        }
               //          //
               //          //
               //          //    }
               //
               //
               //         }
               //     }
               //
               // })
               // yield all(calls)
               //
               // const calls2 =[]
               // payrollData.map(p=>{
               //
               //     if(p&&p.mateId) {
               //         //console.log(p.totalDaysInPayPeriod)
               //         // console.log(p)
               //         const timeSheet = lookup[p.mateId.toLowerCase()] //timesheet.find(t => p.mateId===t.mateId);
               //         if (timeSheet) {
               //                 (!timeSheet.member ||timeSheet.member.employmentType!=='Casual')&& timeSheet.absentDays && calls2.push(put({
               //                    type: 'PAYROLL_ITEM_CHANGED', payload: {
               //                        event: {
               //                            field: 'absentDays',
               //                            value: timeSheet.absentDays,
               //                            dataItem: p
               //                        }
               //                    }
               //                }))
               //
               //                timeSheet.overtimes && calls2.push(put({
               //                    type: 'PAYROLL_ITEM_CHANGED', payload: {
               //                        event: {
               //                            field: 'overtimes',
               //                            value: Object.keys(timeSheet.overtimes).map(key=>{
               //                                const type = overtimeTypes.find(o=>o.name.replace(/\s+/g, '').toLowerCase() === key.replace(/\s+/g, '').toLowerCase())
               //                                if(type)
               //                                {
               //                                    return {typeId: type.id, rate: type.rate, hrs: timeSheet.overtimes[key]}
               //                                }
               //
               //                                }
               //                            ).filter(o=>o.typeId),
               //                            dataItem: p
               //                        }
               //                    }
               //                }))
               //                timeSheet.leaveDays && calls2.push(put({
               //                    type: 'PAYROLL_ITEM_CHANGED', payload: {
               //                        event: {
               //                            field: 'leaveDays',
               //                            value: timeSheet.leaveDays,
               //                            dataItem: p
               //                        }
               //                    }
               //                }))
               //
               //                timeSheet.payableDays && timeSheet.member &&timeSheet.member.employmentType==='Casual' && calls2.push(put({
               //                    type: 'PAYROLL_ITEM_CHANGED', payload: {
               //                        event: {
               //                            field: 'normalPayDays',
               //                            value: timeSheet.payableDays,
               //                            dataItem: p
               //                        }
               //                    }
               //                }))
               //
               //             //   console.log(timeSheet)
               //
               //                if(timeSheet.transportPayableDays||timeSheet.transportPayableDays===0) {
               //                    //console.log(p)
               //                    const newEarnings = p.payrollFixedEarnings.slice(0); // Create a shallow copy of the roles
               //                    //const name = newRoles[index].name
               //
               //                    const index = newEarnings.findIndex(e=>e.name.toLowerCase().replace(/\s+/g, '')==="transportallowance"&&e.dayBased)
               //                    //console.log(index)
               //                    if(index>=0) {
               //                        const val = (newEarnings[index].originalValue / p.totalDaysInPayPeriod||30) * timeSheet.transportPayableDays;
               //
               //                        newEarnings[index] = {
               //                            ...newEarnings[index],
               //                            entitledDays: +timeSheet.transportPayableDays,
               //                            fullValue: val,
               //                            value: val
               //                        }; // Set the new value
               //                        //console.log(newEarnings)
               //                        calls2.push(put({
               //                            type: 'PAYROLL_ITEM_CHANGED', payload: {
               //                                event: {
               //                                    field: 'payrollFixedEarnings',
               //                                    value: newEarnings,
               //                                    dataItem: p
               //                                }
               //                            }
               //                        }))
               //                    }
               //
               //
               //                }
               //
               //
               //         }
               //     }
               //
               // })
               // // console.log(calls2)
               // yield all(calls2)


                   yield put(showNotification(`Timesheet Updated`, "info"))


          //     return {mateId:d.mateId, overtimes:OTs,absentDays:d.absentDays, leaveDays:d.leaveDays, normalPayDays:d.daysWorked, transportPayDays:d.transportDays }



           }






           yield  put({type:'SET_MATES_DONE', payload: true})

       }
       catch (e){
          console.error(e)
           yield put(showNotification(`Sorry, Syncing Failed, please check your connection `, "error"))
           yield  put({type:'SET_MATES_SAVING', payload: false})
       }

    })

    yield takeLatest('LOAD_TIK_CHANGES', function* (action) {
       // console.log('runing')
       //  yield put(fetchEnd());
        if(localStorage.getItem('companyMateId')){
            try {
                yield put(fetchStart());
                const {data: terminations} = yield call(getTerminations, {status: "Approved"})
                const {data: newHires} = yield call(getNewHires, {status: "Approved"})
                const {data: teams} = yield call(getTeams )
                const {data: allSites } = yield  call(OdataServiceProvider.getList,'sites',{pagination:{},sort:{},filter:{}})
                const sites = allSites.map(s=>s.name.toLowerCase().replace(/\s+/g, ''))
                if ((terminations && terminations.length) || (newHires && newHires.length)) {
                    yield  put({type: 'SET_TIK_TERMINATIONS', payload: terminations})
                        yield  put({type: 'SET_TIK_NEW_HIRES', payload: newHires})
                    yield  put({type: 'SET_TIK_TEAMS', payload: teams.filter(t=>sites.includes(t.code.toLowerCase().replace(/\s+/g, '')))})
                    yield  put({type: 'SET_OPENED_DIALOG', payload: 'tikDialog'})
                }
                 else {
                    yield put(showNotification(`No New Updates From Tik`, "info"))

                }
                yield put(fetchEnd());
            } catch (e) {
                console.error(e)
                yield put(fetchEnd());
            }
        }
        // yield put(fetchEnd());

    })

    yield takeLatest('ACCEPT_TIK_CHANGES', function* (action) {

        const {payload} = action

        try {
            if (payload.changes) {
                yield put(fetchStart());
                const {data: emps} = yield  call(OdataServiceProvider.getList, 'employees', {
                    pagination: {},
                    sort: {},
                   // filter: {filter: `mateId in (${payload.terminations.map(x => "'" + x.member.mateId + "'").toString()})`}
                })

                const {data: sites} = yield  call(OdataServiceProvider.getList, 'sites', {
                    pagination: {},
                    sort: {},
                    // filter: {filter: `mateId in (${payload.terminations.map(x => "'" + x.member.mateId + "'").toString()})`}
                })

                const lookup = {}

                for (let i = 0; i < emps.length; i++) {
                    if (emps[i].mateId) {
                        lookup[emps[i].mateId.replace(/\s+/g, '').toLowerCase()] = emps[i]
                    } else {
                        // console.log(timesheet[i])
                    }
                }

                // console.log({lookup})
                const calls = []
                let terminated = 0

                let notFound = []

                let employed = 0
                let alreadyEmployed = []

                const {data }= yield call(OdataServiceProvider.getOne,"companies",{ id: localStorage.getItem('companyId'), expand:`earnings($filter = Scope eq 'Company'),deductions($filter = Scope eq 'Company'),expenses($filter = Scope eq 'Company'),CompanySettings`})
                //console.log(data)
                const settingDays= data.companySettings.find(s=>s.name==='Pension_StartDays')
                const settingFullMonth= data.companySettings.find(s=>s.name==='Pension_FullMonth')
                const StartAfterDays = settingDays?+settingDays.value:0
                const StartFullMonth = settingFullMonth?settingFullMonth.value==='true':true
                const er = data.earnings.map(e => {
                    const f = _.omit(e, ["id", "createdDate", "modifiedDate", "version", "@odata.context", "@odata.etag","positionId", "employeeId"])
                    f.parentScope = f.scope
                    f.scope="Employee"
                    f.copyRefId= e.id
                    return f
                }  )
                const d = data.deductions.map(e => {
                    const f = _.omit(e, ["id", "createdDate", "modifiedDate", "version", "@odata.context", "@odata.etag","positionId", "employeeId"])
                    f.parentScope = f.scope
                    f.scope="Employee"
                    f.copyRefId= e.id
                    return f
                }  )
                const ex = data.expenses.map(e => {
                    const f = _.omit(e, ["id", "createdDate", "modifiedDate", "version", "@odata.context", "@odata.etag","positionId", "employeeId"])
                    f.parentScope = f.scope
                    f.scope="Employee"
                    f.copyRefId= e.id
                    return f
                }  )
                payload.changes.map(t => {

                    if(t.member&&t.member.mateId&&t.type==='termination') {
                        if (lookup[t.member.mateId.replace(/\s+/g, '').toLowerCase()]) {
                            calls.push(call(OdataServiceProvider.update, 'employees', {
                                id: lookup[t.member.mateId.replace(/\s+/g, '').toLowerCase()].id,
                                data: payload.setInactive ? {
                                    contractEndDate: new Date(t.endDate),
                                    active: false
                                } : {contractEndDate: new Date(t.endDate)},

                            }))
                            calls.push(call(updateTermination, {
                                id: t.id,
                                termination: {
                                    status: "Approved&Acknowledged",
                                    acknowledgedBy: localStorage.getItem('user'),
                                    acknowledgedDate: new Date()
                                }
                            }))
                            terminated++
                        } else {
                            notFound.push(t)
                        }
                    }

                    if(t.mateId&&t.type==='newHire') {
                        if (!lookup[t.mateId.replace(/\s+/g, '').toLowerCase()]) {
                            const site = sites.find(s=>t.currentTeam&&s.name.toLowerCase().replace(/\s+/g, '')===t.currentTeam.toLowerCase().replace(/\s+/g, ''))






                            // const {data: sites }= yield call(OdataServiceProvider.getList,"sites")
                            // console.log(appliedHires)




                            const m = {
                                name:t.firstName || t.name,
                                fatherName:t.fatherName,
                                gFatherName:t.gFatherName,
                                employmentType:'FullTime',
                                siteId: site&&site.id,
                                startDate: t.startDate,
                                address: {
                                    phoneNumber:t.phone,
                                    phoneNumbers:[{type:'Mobile', number:t.phone}]

                                },
                                mateId:t.mateId.toLowerCase().replace(/\s+/g, ''),

                            }

                            m.earnings = er.filter(i=>i.appliesTo.includes(m.employmentType)&&(!m.citizenship||!i.appliesToCitizens||!i.appliesToCitizens.length||i.appliesToCitizens.map(ap=>ap.toLowerCase()).includes(m.citizenship.toLowerCase().replace(/\s+/g, ''))))
                            m.deductions = d.filter(i=>i.appliesTo.includes(m.employmentType)&&(!m.citizenship||!i.appliesToCitizens||!i.appliesToCitizens.length||i.appliesToCitizens.map(ap=>ap.toLowerCase()).includes(m.citizenship.toLowerCase().replace(/\s+/g, '')))) // d.filter(i=>i.deductionType.toLowerCase()!=="emppension" || ((!e.citizenship || e.citizenship.toLowerCase().replace(/\s+/g, '') === 'ethiopian' || e.citizenship.toLowerCase().replace(/\s+/g, '') === '')&&e.employmentType!=="PartTime" ));           // skip non ethiopians and parttimeers on penstions
                            m.expenses = ex.filter(i=>i.appliesTo.includes(m.employmentType)&&(!m.citizenship||!i.appliesToCitizens||!i.appliesToCitizens.length||i.appliesToCitizens.map(ap=>ap.toLowerCase()).includes(m.citizenship.toLowerCase().replace(/\s+/g, ''))))

                            const pension = m.deductions.find(d=>d.deductionType==="EmpPension")
                            const compPension = m.expenses.find(d=>d.type==="CompanyPension")
                            if(pension&&m.startDate)
                            {

                                // console.log(companyData)
                                pension.startDate = moment(m.startDate).add(StartAfterDays,"day")
                                if(StartFullMonth){
                                    pension.startDate = pension.startDate.startOf('month')
                                }
                                // form.change('deductions', deductions)

                            }

                            if(compPension&&m.startDate)
                            {

                                // console.log(companyData)
                                compPension.startDate = moment(m.startDate).add(StartAfterDays,"day")
                                if(StartFullMonth){
                                    compPension.startDate = pension.startDate.startOf('month')
                                }
                                //  form.change('expenses', expenses)

                            }



                            calls.push(call(OdataServiceProvider.create, 'employees', {
                                data:m
                            }))
                            calls.push(call(updateNewHires, {
                                id: t.id,
                                newHire: {
                                    status: "Approved&Acknowledged",
                                    }
                            }))
                            employed++
                        } else {
                            alreadyEmployed.push(t)
                            calls.push(call(updateNewHires, {
                                id: t.id,
                                newHire: {
                                    status: "Already Employed",
                                }
                            }))
                        }
                    }



                })
                // console.log(calls)


                if (notFound.length === 0) {
                    yield all(calls)
                    if(terminated>0)yield put(showNotification(`End date of ${terminated} Employees is set`, "info"))
                    if(employed>0)yield put(showNotification(`${employed} New Employees added, Please review and add missing info before running payroll`, "info"))
                    yield  put({type: 'SET_OPENED_DIALOG', payload: ''})
                } else {
                    yield  put({type: 'SET_TIK_NOT_FOUND_TERMINATIONS', payload: notFound})
                    yield put(showNotification(`Sorry we cannot find ${notFound.length} employees`, "error"))

                }

                yield put(refreshView())
                yield put(fetchEnd());
            }
        } catch (e) {
            console.error(e)
            yield put(fetchEnd());
        }








    })

    yield takeLatest('IGNORE_TIK_CHANGES', function* (action) {

        const {payload} = action

        try {
            if (payload.terminations) {
                yield put(fetchStart());

                const calls = []


                payload.terminations.map(t => {
                    if(t.type==='termination') {
                        calls.push(call(updateTermination, {
                            id: t.id,
                            termination: {
                                status: "Approved&Ignored",
                                acknowledgedBy: localStorage.getItem('user'),
                                acknowledgedDate: new Date()
                            }
                        }))
                    }
                    if(t.type==='newHire') {
                        calls.push(call(updateNewHires, {
                            id: t.id,
                            newHire: {
                                status: "Approved&Ignored",
                            }
                        }))
                    }
                })


                // console.log(calls)

                yield all(calls)

                yield  put({type: 'SET_OPENED_DIALOG', payload: ''})
                yield put(showNotification(`${  payload.terminations.length} Employees are Ignored`, "info"))
              //  yield put(showNotification(`${  payload.newHires.length} Employees are Ignored`, "info"))


                yield put(refreshView())
                yield put(fetchEnd());
            }
        } catch (e) {
            console.error(e)
            yield put(fetchEnd());
        }








    })


    yield takeLatest('LOAD_TIK_MATES_CHANGES', function* (action) {
        // console.log('runing')
        //  yield put(fetchEnd());
        if(localStorage.getItem('companyMateId')){
            try {
                yield put(fetchStart());
                const {data: terminations} = yield call(getTerminations, {status: "Approved"})
                const {data: newHires} = yield call(getNewHires, {status: "Approved"})
                const {data: teams} = yield call(getTeams )
                const {data: allSites } = yield  call(OdataServiceProvider.getList,'sites',{pagination:{},sort:{},filter:{}})
                const sites = allSites.map(s=>s.name.toLowerCase().replace(/\s+/g, ''))

                let changes = false
                if ((terminations && terminations.length) || (newHires && newHires.length)) {
                    yield  put({type: 'SET_TIK_TERMINATIONS', payload: terminations})
                    yield  put({type: 'SET_TIK_NEW_HIRES', payload: newHires})
                    yield  put({type: 'SET_TIK_TEAMS', payload: teams.filter(t=>sites.includes(t.code.toLowerCase().replace(/\s+/g, '')))})

                    changes = true
                    // yield  put({type: 'SET_OPENED_DIALOG', payload: 'tikDialog'})
                }
                else {

                    yield put(showNotification(`No New Updates From Tik`, "info"))
                }

                try {
                    // yield put(fetchStart());
                    if(localStorage.getItem('companyMateId')==='deweto') {
                        yield  put({type: 'SET_MATES_LOADING', payload: true})
                        // const {data:timesheet} = yield call(getTimeSheetSummery,{atDate:payload.atDate,month:payload.month})
                        const {data: payrollChanges} = yield call(payrollMembers, {
                            startDate: moment().add(-5, 'years'),
                            endDate: moment().add(1, "month")
                        })

                        const {data: allEmps} = yield  call(OdataServiceProvider.getList, 'employees', {
                            pagination: {},
                            sort: {},
                            filter: {filter: 'active eq true'}
                        })

                        //  console.log(mateMembers)

                        const mateIds = allEmps.map(e => e.mateId && e.mateId.toLowerCase());
                        // console.log(mateMembers)
                        const newEmps = payrollChanges.newMembers.filter(m => !mateIds.includes(m.mateId)).map(m => ({
                            ...m,
                            payrollStatus: 'Applied'
                        }));
                        const inactiveEmps = payrollChanges.inactiveMembers.map(m => ({
                            ...m.member, ...m,
                            memberId: m.member.id,
                            payrollStatus: 'Applied'
                        })).filter(m => mateIds.includes(m.mateId && m.mateId.toLowerCase()));

                        const updatedEmps = payrollChanges.updatedMembers.map(m => ({
                            ...m.member, ...m,
                            payrollStatus: 'Applied'
                        })).filter(m => mateIds.includes(m.mateId && m.mateId.toLowerCase()));

                        // console.log({updatedEmps, mateIds})

                        //console.log(inactiveEmps)
                        // const idleEmps = timesheet.filter(t=>t.status==='Idle')
                        if (newEmps.length || inactiveEmps.length || updatedEmps.length) {
                            changes = true

                            yield  put({type: 'SET_MATES_EMPS', payload: {newEmps, inactiveEmps, updatedEmps}})


                        } else {
                            yield put(showNotification(`No New Updates From Mates`, "info"))
                        }
                    }

                        if (changes) {
                            yield  put({type: 'SET_OPENED_DIALOG', payload: 'tikMatesDialog'})
                        }


                }
                catch (e) {
                    yield put(fetchEnd());
                    yield  put({type:'SET_MATES_ERROR', payload: true})
                    yield put(showNotification(`Error Fetching Changes`, "error"))
                    console.log(e)
                }



                yield put(fetchEnd());
            } catch (e) {
                console.error(e)
                yield put(fetchEnd());
            }
        }
        // yield put(fetchEnd());

    })

    yield takeLatest('ACCEPT_TIK_MATES_CHANGES', function* (action) {

        const {payload} = action

        try {
            if (payload.changes) {
                yield put(fetchStart());
                const {data: emps} = yield  call(OdataServiceProvider.getList, 'employees', {
                    pagination: {},
                    sort: {},
                    filter:{filter:'active eq true'}
                    // filter: {filter: `mateId in (${payload.terminations.map(x => "'" + x.member.mateId + "'").toString()})`}
                })

                const {data: sites} = yield  call(OdataServiceProvider.getList, 'sites', {
                    pagination: {},
                    sort: {},
                    // filter: {filter: `mateId in (${payload.terminations.map(x => "'" + x.member.mateId + "'").toString()})`}
                })

                const lookup = {}

                for (let i = 0; i < emps.length; i++) {
                 console.log(emps[i])
                    if (emps[i].mateId) {
                        lookup[emps[i].mateId.replace(/\s+/g, '').toLowerCase()] = emps[i]
                    } else {
                        // console.log(timesheet[i])
                    }
                }

                // console.log({lookup})
                const calls = []
                let terminated = 0

                let notFound = []

                let employed = 0
                let alreadyEmployed = []

                const {data }= yield call(OdataServiceProvider.getOne,"companies",{ id: localStorage.getItem('companyId'), expand:`earnings($filter = Scope eq 'Company'),deductions($filter = Scope eq 'Company'),expenses($filter = Scope eq 'Company'),CompanySettings`})
                //console.log(data)
                const settingDays= data.companySettings.find(s=>s.name==='Pension_StartDays')
                const settingFullMonth= data.companySettings.find(s=>s.name==='Pension_FullMonth')
                const StartAfterDays = settingDays?+settingDays.value:0
                const StartFullMonth = settingFullMonth?settingFullMonth.value==='true':true
                const er = data.earnings.map(e => {
                    const f = _.omit(e, ["id", "createdDate", "modifiedDate", "version", "@odata.context", "@odata.etag","positionId", "employeeId"])
                    f.parentScope = f.scope
                    f.scope="Employee"
                    f.copyRefId= e.id
                    return f
                }  )
                const d = data.deductions.map(e => {
                    const f = _.omit(e, ["id", "createdDate", "modifiedDate", "version", "@odata.context", "@odata.etag","positionId", "employeeId"])
                    f.parentScope = f.scope
                    f.scope="Employee"
                    f.copyRefId= e.id
                    return f
                }  )
                const ex = data.expenses.map(e => {
                    const f = _.omit(e, ["id", "createdDate", "modifiedDate", "version", "@odata.context", "@odata.etag","positionId", "employeeId"])
                    f.parentScope = f.scope
                    f.scope="Employee"
                    f.copyRefId= e.id
                    return f
                }  )

                const {data: templateEarnings }= yield call(OdataServiceProvider.getList,"earnings",{filter:{filter:`scope eq 'Template'`} })
                const {data: banks }= yield call(OdataServiceProvider.getList,"companyBanks")
                const {data: positions }= yield call(OdataServiceProvider.getList,"positions",{filter:{expand:`earnings,deductions,expenses`}})

                const appliedHires = []
                const appliedTerminations = []
                const appliedUpdates = []
                payload.changes.map(t => {

                    if(t.member&&t.member.mateId&&t.type==='termination') {
                        if (lookup[t.member.mateId.replace(/\s+/g, '').toLowerCase()]) {
                            calls.push(call(OdataServiceProvider.update, 'employees', {
                                id: lookup[t.member.mateId.replace(/\s+/g, '').toLowerCase()].id,
                                data: payload.setInactive ? {
                                    contractEndDate: new Date(t.endDate),
                                    active: false
                                } : {contractEndDate: new Date(t.effectiveDate||t.endDate)},

                            }))
                            if(t.source==='tik') {
                                calls.push(call(updateTermination, {
                                    id: t.id,
                                    termination: {
                                        status: "Approved&Acknowledged",
                                        acknowledgedBy: localStorage.getItem('user'),
                                        acknowledgedDate: new Date()
                                    }
                                }))
                            }
                            else if(t.source==='mates'){
                                appliedTerminations.push({_id:t._id, payrollStatus:'Applied' })
                            }
                            terminated++
                        } else {
                            notFound.push(t)
                        }
                    }

                    if(t.mateId&&t.type==='newHire') {
                        if (!lookup[t.mateId.replace(/\s+/g, '').toLowerCase()]) {
                            t.currentTeam = t.currentTeam || t.joinTeam
                            const site = sites.find(s=>t.currentTeam&&s.name.toLowerCase().replace(/\s+/g, '')===t.currentTeam.toLowerCase().replace(/\s+/g, ''))

                            const m = {
                                name:t.firstName || t.name,
                                fatherName:t.fatherName,
                                gFatherName:t.gFatherName,
                                employmentType:t.employmentType||'FullTime',
                                siteId: site&&site.id,
                                startDate: t.startDate,
                                contractEndDate: t.endDate,
                                address: {
                                    phoneNumber:t.phone,
                                    phoneNumbers: (t.address&&t.address.phones&&t.address.phones.map(ph=>({type:ph.type,number:ph.value}))) || [{type:'Mobile', number:t.phone}],
                                    email: t.address && t.address.email,
                                    addressLine:  t.address && t.address.locationText,
                                    city:  t.address && t.address.city,
                                    subcity:  t.address && t.address.subcity,
                                    kebele:  t.address && t.address.kebele,
                                    woreda:  t.address && t.address.woreda,
                                    houseNumber: t.address && t.address.houseNumber

                                },
                                mateId:t.mateId.toLowerCase().replace(/\s+/g, ''),
                                basicSalary: t.earning&&t.earning.rate,
                                bankAccountNumber:t.bankAccounts&&t.bankAccounts[0]&&t.bankAccounts[0].value,
                                citizenship: t.citizenship

                            }

                            m.earnings = er.filter(i=>i.appliesTo.includes(m.employmentType)&&(!m.citizenship||!i.appliesToCitizens||!i.appliesToCitizens.length||i.appliesToCitizens.map(ap=>ap.toLowerCase()).includes(m.citizenship.toLowerCase().replace(/\s+/g, ''))))
                            m.deductions = d.filter(i=>i.appliesTo.includes(m.employmentType)&&(!m.citizenship||!i.appliesToCitizens||!i.appliesToCitizens.length||i.appliesToCitizens.map(ap=>ap.toLowerCase()).includes(m.citizenship.toLowerCase().replace(/\s+/g, '')))) // d.filter(i=>i.deductionType.toLowerCase()!=="emppension" || ((!e.citizenship || e.citizenship.toLowerCase().replace(/\s+/g, '') === 'ethiopian' || e.citizenship.toLowerCase().replace(/\s+/g, '') === '')&&e.employmentType!=="PartTime" ));           // skip non ethiopians and parttimeers on penstions
                            m.expenses = ex.filter(i=>i.appliesTo.includes(m.employmentType)&&(!m.citizenship||!i.appliesToCitizens||!i.appliesToCitizens.length||i.appliesToCitizens.map(ap=>ap.toLowerCase()).includes(m.citizenship.toLowerCase().replace(/\s+/g, ''))))

                            const pension = m.deductions.find(d=>d.deductionType==="EmpPension")
                            const compPension = m.expenses.find(d=>d.type==="CompanyPension")
                            if(pension&&m.startDate)
                            {

                                // console.log(companyData)
                                pension.startDate = moment(m.startDate).add(StartAfterDays,"day")
                                if(StartFullMonth){
                                    pension.startDate = pension.startDate.startOf('month')
                                }
                                // form.change('deductions', deductions)

                            }

                            if(compPension&&m.startDate)
                            {

                                // console.log(companyData)
                                compPension.startDate = moment(m.startDate).add(StartAfterDays,"day")
                                if(StartFullMonth){
                                    compPension.startDate = pension.startDate.startOf('month')
                                }
                                //  form.change('expenses', expenses)

                            }


                            if(t.earning && t.earning.additionalEarnings && t.earning.additionalEarnings.length) {


                                t.earning.additionalEarnings.map(er => {
                                    const template = templateEarnings.find(e => e.name.toLowerCase().replace(/\s+/g, '') === er.type.toLowerCase().replace(/\s+/g, ''))
                                      console.log("template",template, er.type, templateEarnings)
                                    if (template) {
                                        const x = _.pick(template, ['name', 'taxable', 'taxOption', 'limitByValue', 'valueType', 'dayBased'])
                                        //console.log(e.earnings)
                                        m.earnings.push({
                                            ...x,
                                            value: er.value,
                                            templateId: template.id,
                                            earningType: template.name
                                        })
                                        // if(!e.earnings){
                                        //     e.earnings = []
                                        // }
                                        //   e.earnings.push(
                                        //       {...x,value:e[key]}
                                        //   )

                                    }
                                })
                            }

                            if(t.bankAccounts &&  t.bankAccounts.length && t.bankAccounts[0].type)
                            {
                                const bank = banks.find(p=>p.bank.name.toLowerCase().replace(/\s+/g, '')===t.bankAccounts[0].type.toLowerCase().replace(/\s+/g, ''))
                                if(bank)
                                {m.companyBankId = bank.id;}
                            }

                            if(t.jobTitle)
                            {
                                const pos = positions.find(p=>p.name.toLowerCase().replace(/\s+/g, '')===t.jobTitle.toLowerCase().replace(/\s+/g, ''))

                                if(pos)
                                {
                                    m.positionId = pos.id;

                                    const er = pos.earnings.filter(i=>i.appliesTo.includes(m.employmentType)&&(!m.citizenship||!i.appliesToCitizens||!i.appliesToCitizens.length||i.appliesToCitizens.map(ap=>ap.toLowerCase()).includes(m.citizenship.toLowerCase().replace(/\s+/g, '')))).map(i=> {
                                        // consoli.log(e)
                                        const f = _.omit(i, ["id", "createdDate", "modifiedDate", "version", "@odata.context", "@odata.etag","positionId", "employeeId"])
                                        f.parentScope = f.scope
                                        f.scope="Employee"
                                        f.copyRefId= i.id
                                        //  f.earningType = i.earningType
                                        return f
                                    }  )

                                    const d = pos.deductions.filter(i=>i.appliesTo.includes(m.employmentType)&&(!m.citizenship||!i.appliesToCitizens||!i.appliesToCitizens.length||i.appliesToCitizens.map(ap=>ap.toLowerCase()).includes(m.citizenship.toLowerCase().replace(/\s+/g, '')))).map(i=> {
                                        const f = _.omit(i, ["id", "createdDate", "modifiedDate", "version", "@odata.context", "@odata.etag","positionId", "employeeId"])
                                        f.parentScope = f.scope
                                        f.scope="Employee"
                                        f.copyRefId= i.id
                                        return f
                                    }  )

                                    const ex = pos.expenses.filter(i=>i.appliesTo.includes(m.employmentType)&&(!m.citizenship||!i.appliesToCitizens||!i.appliesToCitizens.length||i.appliesToCitizens.map(ap=>ap.toLowerCase()).includes(m.citizenship.toLowerCase().replace(/\s+/g, '')))).map(i=> {
                                        const f = _.omit(i, ["id", "createdDate", "modifiedDate", "version", "@odata.context", "@odata.etag","positionId", "employeeId"])
                                        f.parentScope = f.scope
                                        f.scope="Employee"
                                        f.copyRefId= i.id
                                        return f
                                    }  )



                                    m.earnings =  m.earnings.concat(er) //pos.earnings.filter(i=>i.appliesTo.includes(e.employmentType)&&(!e.citizenship||!i.appliesToCitizens||!i.appliesToCitizens.length||i.appliesToCitizens.map(ap=>ap.toLowerCase()).includes(e.citizenship.toLowerCase().replace(/\s+/g, ''))))) ;
                                    m.deductions = m.deductions.concat(d) // pos.deductions.filter(i=>i.appliesTo.includes(e.employmentType)&&(!e.citizenship||!i.appliesToCitizens||!i.appliesToCitizens.length||i.appliesToCitizens.map(ap=>ap.toLowerCase()).includes(e.citizenship.toLowerCase().replace(/\s+/g, ''))))) // d.filter(i=>i.deductionType.toLowerCase()!=="emppension" || ((!e.citizenship || e.citizenship.toLowerCase().replace(/\s+/g, '') === 'ethiopian' || e.citizenship.toLowerCase().replace(/\s+/g, '') === '')&&e.employmentType!=="PartTime" ));           // skip non ethiopians and parttimeers on penstions
                                    m.expenses = m.expenses.concat(ex)//pos.expenses.filter(i=>i.appliesTo.includes(e.employmentType)&&(!e.citizenship||!i.appliesToCitizens||!i.appliesToCitizens.length||i.appliesToCitizens.map(ap=>ap.toLowerCase()).includes(e.citizenship.toLowerCase().replace(/\s+/g, '')))));
                                    // console.log(er,e)
                                }



                            }





                            calls.push(call(OdataServiceProvider.create, 'employees', {
                                data:m
                            }))
                            if(t.source === 'tik') {
                                calls.push(call(updateNewHires, {
                                    id: t.id,
                                    newHire: {
                                        status: "Approved&Acknowledged",
                                    }
                                }))
                            }
                            else if (t.source === 'mates') {
                                appliedHires.push({_id:t._id, payrollStatus:'Applied' })
                             }
                            employed++
                        } else {
                            alreadyEmployed.push(t)

                            if(t.source==='tik') {
                                calls.push(call(updateNewHires, {
                                    id: t.id,
                                    newHire: {
                                        status: "Already Employed",
                                    }
                                }))
                            }
                            else if (t.source==='mates') {
                                appliedUpdates.push({_id:t._id, payrollStatus:'Avoided'})
                            }
                        }
                    }


                    if(t.mateId&&t.type==='update') {

                        if (lookup[t.mateId.replace(/\s+/g, '').toLowerCase()]) {
                            calls.push(call(OdataServiceProvider.update, 'employees', {
                                id: lookup[t.mateId.replace(/\s+/g, '').toLowerCase()].id,
                                data:{
                                    ...(t.updateTypes.includes('salary') && {basicSalary: t.salary}),
                                    ...(t.updateTypes.includes('contractDate') && { contractEndDate: t.contractEndDate}),

                                    //todo  earnings
                                }

                            }))
                            appliedUpdates.push({_id:t._id, payrollStatus:'Applied' })
                        } else {
                            notFound.push(t)
                        }



                    }



                })




                // console.log(calls)


                if (notFound.length === 0) {
                    yield all(calls)
                    const ack = yield call(acknowledgeChanges, appliedHires,appliedTerminations, appliedUpdates)
                    if(terminated>0)yield put(showNotification(`End date of ${terminated} Employees is set`, "info"))
                    if(employed>0)yield put(showNotification(`${employed} New Employees added, Please review and add missing info before running payroll`, "info"))
                    yield  put({type: 'SET_OPENED_DIALOG', payload: ''})
                } else {
                    yield  put({type: 'SET_TIK_NOT_FOUND_TERMINATIONS', payload: notFound})
                    yield put(showNotification(`Sorry we cannot find ${notFound.length} employees`, "error"))

                }

                yield put(refreshView())
                yield put(fetchEnd());
            }
        } catch (e) {
            console.error(e)
            yield put(fetchEnd());
        }








    })

    yield takeLatest('IGNORE_TIK_MATES_CHANGES', function* (action) {
        const {payload} = action

        try {
            console.log(payload)
            if (payload.changes) {
                yield put(fetchStart());

                const calls = []

                const appliedUpdates = []
                const appliedTerminations = []
                const appliedHires =[]



                payload.changes.map(t => {

                    if(t.type==='termination'&&t.source==='tik') {
                        calls.push(call(updateTermination, {
                            id: t.id,
                            termination: {
                                status: "Approved&Ignored",
                                acknowledgedBy: localStorage.getItem('user'),
                                acknowledgedDate: new Date()
                            }
                        }))
                    }
                    if(t.type==='newHire'&&t.source==='tik') {
                        calls.push(call(updateNewHires, {
                            id: t.id,
                            newHire: {
                                status: "Approved&Ignored",
                            }
                        }))
                    }

                    if(t.source==='mates') {
                        if(t.type==='termination') {
                           appliedTerminations.push({_id:t._id, payrollStatus:'Avoided'})
                        }
                        if(t.type==='newHire') {
                            appliedHires.push({_id:t._id, payrollStatus:'Avoided'})
                        }
                        if(t.type==='update') {
                            appliedUpdates.push({_id:t._id, payrollStatus:'Avoided'})
                        }

                    }
                })


                // console.log(calls)

                yield all(calls)

                const ack = yield call(acknowledgeChanges, appliedHires,appliedTerminations, appliedUpdates)

                yield  put({type: 'SET_OPENED_DIALOG', payload: ''})
                yield put(showNotification(`${  payload.changes.length} Employees are Ignored`, "info"))
                //  yield put(showNotification(`${  payload.newHires.length} Employees are Ignored`, "info"))


                yield put(refreshView())
                yield put(fetchEnd());
            }
        } catch (e) {
            console.error(e)
            yield put(fetchEnd());
        }

    })

}
