import {fetchUtils} from 'react-admin';
import {stringify} from "query-string";
import moment from "moment";
const fetchJson = (url, options = {}) => {
    const api = localStorage.getItem('timeAttendanceApi')
    if (!options.headers) {
        options.headers = new Headers({ Accept: 'application/json' });
    }
   let query = ''
    if(options.query)
    {
        query = "?" + stringify(options.query)
    }
    // add your own headers here
   // const token = localStorage.getItem('token');
    //options.headers.set('Authorization', `Bearer ${token}`);
    options.headers.set('x-company', localStorage.getItem('companyMateId').toUpperCase());
    try {
        return fetchUtils.fetchJson(api+url+query, options);
    }
    catch (e) {
        console.log(e)
    }

}



export function getMonthlyAttendanceHours({month, year,fillRest,maxHours}) {
   return fetchJson('/hours', {
    query:{
        month, year, fillRest, maxHours
    }
}).then(_a => {
       let json =_a.json;
       return {data:json}
   })
}

export function getMonthlyTimeSheet({startDate,endDate, month, year, currentTeam}) {
    return fetchJson('/timeSheets', {
        query:{
            startDate:moment(startDate).format("YYYY-MM-DD"), endDate:moment(endDate).format("YYYY-MM-DD"), month, year, currentTeam
        }
    }).then(_a => {
        let json =_a.json;
        return {data:json}
    })
}

export function getAbsentDaysFromOffencePenality({startDate,endDate}) {
    return fetchJson('/offencesForPayroll', {
        query:{
            startDate:moment(startDate).format("YYYY-MM-DD"), endDate:moment(endDate).format("YYYY-MM-DD")
        }
    }).then(_a => {
        let json =_a.json;
        return {data:json}
    })
}

export function getTerminations({status}) {
    return fetchJson('/terminations', {
        query:{
            status
        }
    }).then(_a => {
        let json =_a.json;
        return {data:json}
    })
}

export function updateTermination({id,termination}) {
    return fetchJson('/updateTermination', {
        method: 'POST',
        body: JSON.stringify({id, termination}),
    }).then(_a => {
        let json =_a.json;
        return {data:json}
    })
}

export function getNewHires({status}) {
    return fetchJson('/newHires', {
        query:{
            status
        }
    }).then(_a => {
        let json =_a.json;
        return {data:json}
    })
}

export function getTeams() {
    return fetchJson('/teams', {

    }).then(_a => {
        let json =_a.json;
        return {data:json}
    })
}

export function updateNewHires({id,newHire}) {
    return fetchJson('/updateNewHires', {
        method: 'POST',
        body: JSON.stringify({id, newHire}),
    }).then(_a => {
        let json =_a.json;
        return {data:json}
    })
}
