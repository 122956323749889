import React, { useState } from 'react';
import { Button, useNotify, useRefresh, useUnselectAll, useUpdateMany } from "react-admin";
import Popover from "@material-ui/core/Popover";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import { useQueryWithStore } from "react-admin";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import ActiveIcon from "@material-ui/icons/AddLocationOutlined";

const MoveToCostCenterPopover = props => {

    const [anchorEl, setAnchorEl] = React.useState(null);
    const [costcenter, setCostCenter] = useState(0);
    const { selectedIds } = props;
    const refresh = useRefresh();
    const notify = useNotify();
    const unselectAll = useUnselectAll();
    const [updateMany] = useUpdateMany(
        'employees',
        selectedIds,
        { costCenterId: costcenter === "null" ? null : costcenter },
        {
            onSuccess: () => {
                refresh();
                notify(` ${selectedIds.length} Employees Moved to ${costcenter === "null" ? "No CostCenter" : costcenters.find(s => s.id === costcenter).name}`);
                unselectAll('employees');
            },
            onFailure: error => notify('Error: Some Employees cannot be moved', 'warning'),
        }
    );


    const handleClick = event => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleMove = () => {
        updateMany()
        setAnchorEl(null);
    };
    const { data: costcenters } = useQueryWithStore({
        type: 'getList',
        resource: 'costcenters',
        payload: { filter: {}, pagination: {}, sort: {} }
    });

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;
    return (
        <div>
            <Button label={props.label} aria-describedby={id} variant="text" color="primary" onClick={handleClick}>
                <ActiveIcon />
            </Button>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
            >
                <Card style={{ minWidth: 100 }}>
                    <CardContent>
                        <FormControl margin='normal' >
                            <InputLabel shrink id="demo-simple-select-placeholder-label-label">
                                To CostCenter
                            </InputLabel>
                            <Select
                                value={costcenter}
                                onChange={ev => {
                                    setCostCenter(ev.target.value)
                                }
                                }
                            >
                                <MenuItem key="no-dep" value="null" >No CostCenter</MenuItem>
                                {costcenters && costcenters.map(ot => <MenuItem key={ot.id} value={ot.id}>{ot.name}</MenuItem>)}
                            </Select>
                        </FormControl>

                    </CardContent>
                    <CardActions>
                        <Button label='OK' disabled={!costcenter} style={{ marginLeft: 'auto' }} onClick={handleMove} variant="text" color="primary">

                        </Button>
                    </CardActions>
                </Card>

            </Popover>
        </div>
    );
};

export default MoveToCostCenterPopover