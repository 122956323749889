import React, {useRef, useState} from 'react';
import {DateField, NumberField, TextField, Title, useQueryWithStore} from "react-admin";
import {Card, CardContent} from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import ReactToPrint from "react-to-print";
import Button from "@material-ui/core/Button";
import PrintIcon from "@material-ui/icons/Print";
import {MonthSelection} from "../employeeReport";
import {connect, useDispatch} from "react-redux";
import Typography from "@material-ui/core/Typography";
import MyDatagrid from "../../accountsGrid";
import moment from "moment";
import MultiCurrencyField from "../../../UIComponents/multiCurrencyField";
import MultiSelect from "react-multi-select-component";
import EmployeeReportExcelExport from "../employeeReportExcelExport";
import PayrollStatementExcelExport from "./payrollStatementExcelExport";

const EmployeePayrollStatement = ({payrolls}) => {
    const componentRef = useRef();
    const [selectedEmployees, setSelectedEmployees] = useState([])

    const dispatch = useDispatch();
    const handleOk = (payrollRuns)=>{
        dispatch({
            type: "GET_PAYROLL_STATEMENT",
            payload: {
                payrollRuns,
            }
        })
    }
     const {data: employees =[] } = useQueryWithStore({
         type: 'getList',
         resource: 'employees',
         // payload: {  filter: {filter: 'active eq true'}}
     });

    const filteredPayrolls = payrolls.filter(p=>!selectedEmployees.length||selectedEmployees.map(e=>e.value).includes(p.employeeId))

    const initColumns = [
        {field:"sn", width:80, show:true, title:"SN", type:"Text"},
        {show:true,field:"fullName", width:230, title:"Full Name", type:"Text"},
        {show:true,field:`payrollRun.payrollDate`,  title:"Payroll Date", type:"Date"},
        {show:true,field:`basicSalary`,  title:"Basic Salary",  type:"Number"},
        {show:true,field:`leaveDays`,  title:"Leave Days",  type:"Number"},
        {show:true,field:`absentDays`,  title:"Absent Days",  type:"Number"},
        {show:true,field:`totalIncome`,  title:"Total Income",  type:"Number"},
        {show:true,field:`totalIncome`,  title:"Total Income",  type:"Number"},
        {show:true,field:`taxableIncome`,  title:"taxableIncome",  type:"Number"},
        {show:true,field:`incomeTax`,  title:"incomeTax",  type:"Number"},
        {show:true,field:`empPension`,  title:"Pension Employee",  type:"Number"},
        {show:true,field:`companyPension`,  title:"Pension Company",  type:"Number"},
         


        {show:true,field:`cash`,  title:"Net Pay",  type:"Number"},
    ]

    return (
        <Card>
            <Title title="Employee Payroll Statement"/>



            <Card>
                <CardContent>
                    <Grid container>
                        <MonthSelection handleOk={handleOk}/>
                        <MultiSelect
                            value={selectedEmployees}

                            options={employees.map(e=>({label:(e.orgID||'')+(e.orgID?'-':'')+e.fullname,value:e.id}))}
                            onChange={values=>{
                                setSelectedEmployees(values)
                                // filterPayrolls({value:values,field:'employeeIds'})
                                // setFilteredPayrolls(payrollRun.payrolls.filter(p=>!values||!values.length||values.some(v=>v.value===p.employeeId)))
                            }}
                            labelledBy="Select"
                            valueRenderer={(selected, _options) => {
                                // return selected.length
                                //     ? selected.map(({ label }) => "✔️ " + label)
                                //     : "😶 No Items Selected";
                                if(!selected.length){
                                    return "Filter By Employees"
                                }
                                if(selected.length === employees.length)
                                {
                                    return ["All Employees"]
                                }
                                if(selected.length>3)
                                {
                                    return   selected.slice(0, 3).map(({ label }) => `${label}, `).concat(` + ${selected.length-3}`)
                                }

                                return selected.map(({ label }) => `${label}, `)
                            }}
                        />
                    </Grid>
                    <Grid container>
                        <ReactToPrint
                            trigger={() => <Button variant="outlined"  color="primary"  startIcon={<PrintIcon/>}  >Print/PDF</Button>}
                            content={() => componentRef.current}
                            pageStyle={""}
                        />
                        <PayrollStatementExcelExport label="Export Statement" footerItems={[]} showFooter={false}  data={filteredPayrolls}  columns={initColumns} fileName={'PayrollStatementReport.xlsx'}  title={`Payroll Statement Report`}/>
                    </Grid>
                    <Grid  ref={componentRef} direction="column" container spacing={10}>
                        <Grid item >

                            <Typography variant="h5">New Hires</Typography>
                            <MyDatagrid fullHeight checkField={false}  summeryFields={['basicSalary','totalIncome','taxableIncome', 'incomeTax', 'empPension', 'companyPension', 'cash']} data={filteredPayrolls} ids={filteredPayrolls.map((e,i)=>i)} currentSort={{ field: 'payrollDate', order: 'desc' }}  setSort={()=>{}}>
                                {/*<NumberField  source="sn" />*/}
                                <TextField  source="fullName" />
                                {/*<MultiCurrencyField  source={"fullBasicSalary"}/>*/}
                                <DateField label="Payroll Date" source="payrollRun.payrollDate"/>
                                <MultiCurrencyField label={"Salary"}  source={"basicSalary"}/>
                                <NumberField label={"Leave Days"}  source={"leaveDays"}/>
                                <NumberField label={"Absent Days"}  source={"absentDays"}/>
                                <MultiCurrencyField label={"Gross Income"}  source={"totalIncome"}/>
                                <MultiCurrencyField label={"Taxable Income"}  source={"taxableIncome"}/>
                                <MultiCurrencyField label={"Income Tax"}  source={"incomeTax"}/>

                                <MultiCurrencyField label={"Pension Employee"}  source={"empPension"}/>
                                <MultiCurrencyField label={"Pension Company"}  source={"companyPension"}/>

                                <MultiCurrencyField  label={"Net Pay"} source={"cash"}/>
                            </MyDatagrid>


                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
        </Card>


    );
};

export default connect(state=>({
   payrolls: state.report.payrollStatement,
}))(EmployeePayrollStatement);